<template>
  <div>
    <div class="grid">
      <div class="col-12 md:col-6">
        <h3>CEPS Manuais</h3>
      </div>
    </div>

    <div class="grid">
      <!-- Formulário de Cadastro de CEP -->
      <div class="col-12">
        <div class="card p-fluid">
          <h5 v-if="cadastro">Cadastro Manual de CEP</h5>
          <h5 v-else>Alteração de CEP Manual</h5>

          <div class="grid">

            <div class="p-field md:col-4">
              <label for="nome">Descrição (Resumida)</label>
              <InputText id="nome" type="text" v-model="nome" />
            </div>

            <div class="p-field md:col-2">
              <label for="cep_cep">CEP (Apenas números)</label>
              <InputText id="cep_cep" type="text" v-model="cep_cep" />
            </div>

            <div class="p-field md:col-2">
              <label for="tipo_logradouro">Tipo Logradouro</label>
              <InputText id="tipo_logradouro" type="text" v-model="tipo_logradouro" />
            </div>

            <div class="p-field md:col-4">
              <label for="logradouro">Logradouro</label>
              <InputText id="logradouro" type="text" v-model="logradouro" />
            </div>

            <div class="p-field md:col-3">
              <label for="bairro">Bairro</label>
              <InputText id="bairro" type="text" v-model="bairro" />
            </div>

            <div class="p-field md:col-3">
              <label for="cidade">Cidade</label>
              <InputText id="cidade" type="text" v-model="cidade" />
            </div>

            <div class="p-field md:col-1">
              <label for="uf">UF</label>
              <InputText id="uf" type="text" v-model="uf" />
            </div>

            <div class="p-field md:col-2">
              <label for="lat">Latitude</label>
              <InputText id="lat" type="text" v-model="lat" />
            </div>

            <div class="p-field md:col-2">
              <label for="lng">Longitude</label>
              <InputText id="lng" type="text" v-model="lng" />
            </div>

            <div class="col-12" v-if="cadastro">
              <Button
                id="buttonGravarCEP"
                label="Gravar Novo CEP"
                icon="pi pi-check"
                class="p-button-success"
                @click="adicionar"
              ></Button>
            </div>

            <div class="col-12" v-else>
              <div class="grid">
                <div class="md:col-6">
                  <Button
                    id="buttonAlterarCEP"
                    label="Alterar CEP"
                    icon="pi pi-check"
                    class="p-button-success"
                    @click="alterar"
                  ></Button>
                </div>
                <div class="md:col-6">
                  <Button
                    id="buttonCancelAlteracaoCEP"
                    label="Cancelar Alteração"
                    icon="pi pi-times"
                    class="p-button-warning"
                    @click="cancelarAlteracao"
                  ></Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="grid">
      <!-- Listagem de CEPs -->
      <div class="col-12 md:col-8">
        <DataTable :value="ceps" :lazy="true" :loading="loading">
          <Column class="col-cep" field="cep" header="CEP"></Column>
          <Column class="col-logradouro" field="logradouro" header="Logradouro"></Column>
          <Column class="col-bairro" field="bairro" header="Bairro"></Column>
          <Column class="col-cidade" field="cidade" header="Cidade"></Column>

          <Column class="col-actions">
            <template #body="slotProps">
              <Button
                icon="pi pi-pencil"
                class="p-button-rounded p-button-warning mr-2"
                @click="$router.push({ path: `/ceps/${slotProps.data.cep}` })"
              />
              <Button
                icon="pi pi-trash"
                class="p-button-rounded p-button-danger"
                @click="excluir($event, slotProps.data)"
              />
            </template>
          </Column>
          <template #empty> Nenhum CEP encontrado. </template>
        </DataTable>
      </div>
      <div class="col-12 md:col-4">
        <DataTable :value="ceps_invalidos" :lazy="true" :loading="loading_invalidos">
          <Column class="col-cep" header="CEP Inválido! (auto atualizável)">
            <template #body="slotProps">
              <div :title='JSON.stringify(slotProps.data)' style="position: relative">
                <Button
                  icon="pi pi-trash"
                  class="p-button-rounded p-button-danger"
                  style="position: absolute; top: -6px; right: -6px; height: 1.6rem; width: 1.6rem;"
                  @click="excluirTmp($event, slotProps.data)"
                />
                <strong>{{ slotProps.data.cep }}</strong>                
                <div v-if="slotProps.data.usuario"><u>Loja:</u> {{ slotProps.data.usuario }}</div>
                <div v-if="slotProps.data.pedido"><u>Pedido:</u> {{ slotProps.data.pedido }}</div>
                <div v-if="slotProps.data.gps"><u>Identificado:</u> {{ slotProps.data.gps }}</div>
                <div v-if="slotProps.data.endereco"><u>Endereço Recebido:</u><br>{{ slotProps.data.endereco }}</div>
              </div>
            </template>
          </Column>
          <template #empty> Nenhum CEP inválido. </template>
        </DataTable>
      </div>
    </div>

    <Toast position="top-right" />
  </div>
</template>

<script>
import firebase from "firebase/app";
import "firebase/database";
import axios from 'axios';

export default {
  data() {
    return {
      //Controle de Apresentação
      cadastro: true,
      loading: true,
      loading_invalidos: true,

      // Campos do Formulário
      cep_cep: "",
      nome: "",
      tipo_logradouro: "",
      logradouro: "",
      bairro: "",
      cidade: "",
      uf: "",
      lat: "",
      lng: "",

      // Dado selecionado
      cep: null,

      // Dados cadastrados
      ceps: [],
      ceps_invalidos: [],
    };
  },

  mounted() {
    const self = this;

    var ref = firebase.database().ref("CEPSDSM");
    ref.once("value", function (snapshot) {

      if (snapshot.exists()) {
        self.ceps = Object.values(snapshot.val());
        self.loading = false;
      }

    });

    firebase.database().ref("CEPSDSMInvalidos")
    .on("value", function (snapshot) {

      if (snapshot.exists()) {
        self.ceps_invalidos = Object.values(snapshot.val());
        self.loading_invalidos = false;
      } else {
        self.ceps_invalidos = [];
        self.loading_invalidos = false;
      }

    });

    this.carregarObjetoRota();
  },

  watch: {
    $route() {
      this.carregarObjetoRota();
    },

    cep: function (val) {
      if (val == null) {
        this.limparFormulario();
      } else {
        // Altera tela para modo de edição
        this.cadastro = false;

        //Popula a tela de alterar CEP
        this.cep_cep = val.cep;
        this.nome = val.nome;
        this.tipo_logradouro = val.tipo_logradouro;
        this.logradouro = val.logradouro;
        this.bairro = val.bairro;
        this.cidade = val.cidade;
        this.uf = val.uf;
        this.lat = val.lat;
        this.lng = val.lng;
        
      }
    },
  },

  methods: {
    // // // Métodos padrões para este modelo de tela // // //

    carregarObjetoRota() {
      const self = this;

      if (
        typeof this.$route.params.id != "string" ||
        this.$route.params.id == ""
      )
        return;

      var interval = setInterval(() => {
        if (!self.loading) {
          clearInterval(interval);

          var _cep = self.ceps.filter((obj) => obj.id == self.$route.params.id);

          if (_cep.length > 0) {
            self.cep = _cep[0];
            return;
          }

            var ref = firebase.database().ref("CEPSDSM").child(self.$route.params.id);
            ref.once("value", function (snapshot) {

                if (snapshot.exists()) {
                    self.cep = snapshot.val();
                } else {
                    alert("CEP NÃO ENCONTRADO!");
                    self.$router.push({ path: `/ceps` });
                }

            });

        }
      }, 500);
    },

    limparFormulario() {
      //Limpa as variáveis
      this.cep_cep = "";
      this.nome = "";
      this.tipo_logradouro = "";
      this.logradouro = "";
      this.bairro = "";
      this.cidade = "";
      this.uf = "";
      this.lat = "";
      this.lng = "";

      //limpa o objeto do CEP
      this.cep = null;

      // Altera tela para modo de edição
      this.cadastro = true;
    },

    // // // AÇÕES DO USUÁRIO // // //

    buscaCEP() {

      axios
      .get("https://brasilapi.com.br/api/cep/v1/" + this.cep_cep)
      .then(function (response) {
        //Sucesso
        if (self.uf == "") self.uf = response.data.state;
        if (self.cidade == "") self.cidade = response.data.city;
        if (self.bairro == "") self.bairro = response.data.neighborhood;
        if (self.logradouro == "") self.logradouro = response.data.street;
      })
      .catch(function (/*error*/) {
        //Erro
        // if (error.response.data.name == "CepPromiseError")
        //   self.cepInvalido = true;
      });

    },

    cancelarAlteracao() {
      this.limparFormulario();

      this.$router.push({ path: `/ceps` });
    },

    criar_alterar(criar = true) {
      const self = this;

      if(this.cep_cep.trim() == "" || this.lat.trim() == "" || this.lng.trim() == "") {
        alert('CEP, Latitude ou Longitude não informados!');
        return false;
      }

      var dados = {
        cep: this.cep_cep.trim(),
        nome: this.nome.trim(),
        tipo_logradouro: this.tipo_logradouro.trim(),
        logradouro: this.logradouro.trim(),
        bairro: this.bairro.trim(),
        cidade: this.cidade.trim(),
        uf: this.uf.trim(),
        lat: this.lat.trim(),
        lng: this.lng.trim()
      };

      firebase.database().ref("CEPSDSM").child(dados.cep).set(dados)
      .then(function() {
        
        firebase.database().ref("CEPSDSMInvalidos").child(dados.cep).remove()
        .then(function() {

          var acao = "alterado";

          if (criar) {
              self.ceps.unshift(dados);
              acao = "criado";
          } else {
              for (const k of Object.keys(self.ceps)) {
                  if (self.ceps[k].cep == dados.cep) {
                      self.ceps[k] = dados;
                      break;
                  }
              }
          }

          self.$toast.add({
              severity: "success",
              summary: "Sucesso!",
              detail: "CEP " + acao + " com sucesso!",
              life: 3000,
          });

          self.cancelarAlteracao();

        }).catch(function(error) {

          if (error) {
              self.$toast.add({
                  severity: "error",
                  summary: "ERRO!",
                  detail: error.message,
                  life: 5000,
              });
          }

        });

      }).catch(function(error) {

        if (error) {
            self.$toast.add({
                severity: "error",
                summary: "ERRO!",
                detail: error.message,
                life: 5000,
            });
        }

      });

    },

    alterar() {
      this.criar_alterar(false);
    },

    adicionar() {
      this.criar_alterar();
    },

    excluir(event, obj) {

      const self = this;

        if (!confirm("Tem certeza que deseja excluir?"))
          return false;

        var ref = firebase.database().ref("CEPSDSM").child(obj.cep);
        ref.remove().then(function() {

            for (const k of Object.keys(self.ceps)) {
                if (self.ceps[k].cep == obj.cep) {
                self.ceps.splice(k);
                break;
                }
            }

            self.$toast.add({
                severity: "success",
                summary: "Sucesso!",
                detail: "CEP excluído com sucesso!",
                life: 3000,
            });

        }).catch(function(error) {

            if (error)
            self.$toast.add({
                severity: "error",
                summary: "ERRO!",
                detail: error.message,
                life: 5000,
            });

        });

    },
    excluirTmp(event, obj) {

      const self = this;

      if (!confirm("Tem certeza que deseja excluir?"))
        return false;

      firebase.database().ref("CEPSDSMInvalidos").child(obj.cep).remove()
      .then(function() {

          for (const k of Object.keys(self.ceps_invalidos)) {
              if (self.ceps_invalidos[k].cep == obj.cep) {
              self.ceps_invalidos.splice(k);
              break;
              }
          }

          self.$toast.add({
              severity: "success",
              summary: "Sucesso!",
              detail: "CEP excluído com sucesso!",
              life: 3000,
          });

      }).catch(function(error) {

          if (error)
          self.$toast.add({
              severity: "error",
              summary: "ERRO!",
              detail: error.message,
              life: 5000,
          });

      });

    },
  },
};
</script>

<style scoped>

.col-actions {
    width: 100px;
}

</style>